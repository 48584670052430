.create-Themes-board {
  border-top: 1px solid #707070;
  border-left: 1px solid #707070;
  border-right: 2px solid #707070;
  background-color: #ffffff;
  height: 100vh;
  width: 100%;
  padding: 1rem 5rem 1rem 2rem;
  white-space: nowrap;
}
.themes-wordscramble h5 {
  color: #4b4b4b;
  text-transform: capitalize;
  font-size: 20px;
  line-height: 3rem;
}
.themes-wordscramble button {
  margin-right: 1rem;
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
.themes-wordscramble h6{
    color:#4B4B4B;
    margin-top: 1rem;
}
.themes-right span {
  margin-top: 2rem;
  float: right;
  color: #4b4b4b;
  text-transform: uppercase;
}
.themes-right span button {
  margin-left: 1rem;
  background-color: #f0ac20;
  color: #fff;
  border: none;
  padding: 0rem 0.5rem 0.3rem 0.5rem;
  font-size: 20px;
}
