/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

/* Delete POP UP */
.open_file_container {
  background-color: #fff;
  margin: 0 auto;
  width: 30%;
  height: 25%;
  z-index: 2;
  padding: 0px;
  position: absolute;
  top: 45%;
  right: 43%;
  border-radius: 4px;
  box-shadow: 2px 4px 8px #d3d3d3;
}
.open_file_container h5 {
  font-size: 15px;
  text-align: center;
  padding-top: 2rem;
}
.open_file_container button {
  display: flex;
  margin-top: 2rem;
}
.open_file_container #cancel {
  background-color: #d6d6d6;
  color: #fff;
  margin-left: 14rem;
  border: none;
  font-size: 12px;
  text-align: center;
  padding: 0.3rem 0.7rem 0.2rem 0.7rem;
  border-radius: 4px;
}
.open_file_container #delete {
  background-color: #f0ac20;
  color: #fff;
  margin-left: 2rem;
  border: none;
  font-size: 12px;
  text-align: center;
  padding: 0.3rem 0.7rem 0.2rem 0.7rem;
  border-radius: 4px;
}
