body {
  background-color: #f5f5f5;
  font-family: "Poppins", sans-serif;
}
.profile-container h4 {
  font-size: 34px;
  color: #6c6c6c;
  font-weight: 500;
  padding: 1rem;
}
@media (max-width: 1111px) {
  .profile-container h4 {
    font-size: 34px;
  }
}
@media (max-width: 450px) {
  .profile-container h4 {
    font-size: 25px;
  }
}
@media (max-width: 250px) {
  .profile-container h4 {
    font-size: 20px;
  }
}
.profile-container .card {
  border: none;
}
.profile-container .card-header {
  background-color: #b7e6e4;
  box-shadow: 0px 3px 6px #00000029;
  height: 60px;
}
.profile-container .card-header p {
  padding: 10px;
  font-size: 18px;
  color: #111;
  font-weight: 500;
}
.profile-container .card-body {
  background-color: white;
  opacity: 100%;
}
.profile-form {
  padding: 2rem;
}
.profile-form img {
  width: 70%;
}
.profile-form label {
  font-size: 12px;
  color: #08134a;
  opacity: 0.6;
}
.profile-form input {
  background: #e9ecff80 0% 0% no-repeat padding-box;
  border: 1px solid #e9edf0;
  border-radius: 6px;
  font-size: 12px;
  color: #08134a;
  font-weight: 600;
  opacity: 100%;
  width: 60%;
  margin-bottom: 0.8rem;
}
@media (max-width: 780px) {
  .profile-form img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}
@media (max-width: 780px) {
  .profile-form input {
    width: 100%;
  }
}
