.navbar-top {
  background-color: #03b5aa;
}
.notification-Img img {
  width: 2.5rem;
}

.home-Icon {
  color: #fff;
  font-size: 2rem;
}
.home-Icon1 {
  color: #fff;
  background-color: transparent;
  width: 2%;
  padding: 0px 0px 2px 0px;
  font-size: 1rem;
  margin: 1.5rem 1rem 1rem 2rem;
  position: absolute;
  left: 0;
  top: 0;
  border: none;
  box-shadow: #4bd32b;
}
.edit-puzzle-home-Icon {
  color: #fff;
  background-color: transparent;
  width: 2%;
  padding: 0px 0px 2px 0px;
  font-size: 1rem;
  margin: 1.5rem 1rem 1rem 2rem;
  position: absolute;
  left: 0;
  top: 0;
  border: none;
  box-shadow: #4bd32b;
}
.word-type {
  margin: 1rem 1rem 1rem 2rem;
  color: #fbfbfb;
  font-size: 16px;
}
.profile-dropdown {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  line-height: 12px;
}
.profile-dropdown span {
  font-size: 12px;
  color: #020723;
  opacity: 1;
}
.profile-dropdown .dropdown-toggle {
  font-size: 10px;
  color: #020723;
  /* opacity: 0.5; */
  font-weight: 600;
  position: relative;
  left: -0.5rem;
}
.profile-dropdown .dropdown-item i {
  font-size: 12px;
  font-family: normal Poppins;
  letter-spacing: 0px;
  color: #111111;
  padding-right: 1rem;
}
.profile-dropdown .dropdown-item {
  font-size: 14px;
  letter-spacing: 0px;
  color: #111111;
}
