/* CreatePuzzle_wrapper CSS */
.CreatePuzzle_wrapper {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 100%;
}
.CreatePuzzle_wrapper .navbar {
  height: 4rem;
}
#create-puzzle-data p {
  color: #03b5aa;
  font-size: 16px;
  padding: 0.5rem 0rem 0rem 2rem;
  opacity: 100%;
  font-weight: 500;
}
.edit-puzzle-button {
  float: right;
  margin: 1.5rem 0rem 0rem 8rem;
  height: 25px;
  display: flex;
  background-color: transparent;
  border: none;
  /* color:#03B5AA; */
  color: #3a3120;
  font-size: 20px;
  border-radius: 4px;
  padding: 2px 0px 4px 2px;
}
#question p {
  color: #3a3120;
  opacity: 100%;
  font-size: 16px;
  padding-bottom: 1px;
  margin-left: 4rem;
}
#question-edit p {
  color: #3a3120;
  opacity: 100%;
  font-size: 16px;
  padding-bottom: 1px;
  margin-left: 16rem;
}
#themes p {
  color: #707070;
  opacity: 0.8;
  font-size: 16px;
  padding-left: 1rem;
}
#duration p {
  color: #4b4b4b;
  font-size: 16px;
  float: right;
  padding-left: 22rem;
}
#duration input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  color: #4b4b4b;
  opacity: 0.5;
  font-size: 14px;
  margin-left: 10px;
  border-radius: 4px;
  padding: 0rem 1rem 0rem 1rem;
}
#preview p {
  color: #4b4b4b;
  font-size: 14px;
  padding-left: 5rem;
  font-weight: 500;
}
#preview-close i {
  color: #030303;
  padding-left: 8rem;
  font-size: 18px;
  font-weight: 500;
}
.create-puzzle-data .form-Dropdown {
  padding: 2.5rem;
  margin-bottom: 2rem;
  width: 99%;
}
.create-puzzle-data .MuiInputBase-root {
  margin-bottom: 2.5rem;
  height: 2.5rem;
}
.create-puzzle-data .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 1px solid #707070 !important;
}
.create-puzzle-data .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #03b5aa !important;
}
.create-puzzle-data .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #2b4255;
  font-size: 13px !important;
  font-weight: 600 !important;
}
.create-Puzzle .css-q8hpuo-MuiFormControl-root {
  font-size: 13px !important;
}

.create-question-board {
  border-top: 1px solid #707070;
  border-left: 1px solid #707070;
  border-right: 2px solid #707070;
  background-color: #ffffff;
  height: 100vh;
  width: 100%;
  padding: 2rem;
  white-space: nowrap;
}
.create-question-board label {
  color: #3a3120;
  font-size: 13px;
  font-weight: 500;
  padding-top: 1rem;
}
.create-question-board .form-select {
  width: 95%;
  font-size: 13px;
  color: #000000;
  opacity: 30%;
  border: 2px solid #707070;
  height: 2.8rem;
}
.create-question-board .form-select option {
  font-size: 13px;
  color: #000000;
  opacity: 30%;
}
.create-question-board input {
  width: 100%;
  font-size: 13px;
  color: #000000;
  opacity: 30%;
  border: 2px solid #707070;
  height: 2.8rem;
}
.puzzle-table-crud {
  border-right: 1px solid #707070;
  border-left: 1px solid #707070;
  border-top: 1px solid #707070;
}
.puzzle-table-crud thead tr {
  border: none;
}
.puzzle-table-crud thead tr th {
  border: none;
  font-size: 13px;
  font-weight: 500;
  padding-left: 1rem;
}
.puzzle-table-crud tr {
  border-bottom: 2px solid #707070;
  /* opacity: 50%; */
}
#puzzle-sno {
  width: 10%;
  text-align: center;
  color: #000000;
  opacity: 100%;
}
.puzzle-table-crud button {
  float: right;
  background-color: #f0ac20;
  border: none;
  margin-right: 1rem;
  padding: 0px 5px 0px 5px;
  color: #f7f7f7;
  border-radius: 4px;
}
.puzzle-footer {
  display: inline-flex;
  margin-top: 2rem;
}
.puzzle-footer button,
p {
  color: #03b5aa;
  border: none;
  background-color: transparent;
  display: flex;
  margin-top: 1rem;
}
.puzzle-footer button {
  font-weight: 500;
  font-size: 18px;
}
.puzzle-footer p {
  font-size: 14px;
  padding-left: 1rem;
}

.puzzle-footer .form-check-input {
  width: 20px;
  height: 20px;
  margin-top: 1rem;
  border: 3px solid #4b4b4b;
}
.puzzle-footer .form-check-input:checked {
  background-color: #00ad49;
  border-color: #4b4b4b;
  opacity: 100%;
}
.form-check-input:focus,
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none !important;
}
.puzzle-footer label {
  color: #4b4b4b;
  padding-left: 0.5rem;
  font-weight: 500;
}
.puzzle-footer .puzzle-create-button {
  background-color: #f0ac20;
  color: #ffffff;
  font-size: 12px;
  border-radius: 4px;
  text-align: center;
  padding: 5px 15px;
  float: right;
}
/* #create-Questions {
  color: #3a3120;
  opacity: 100%;
  font-size: 14px;
  padding-bottom: 1px;
  padding: 1.5rem 0rem 0rem 2rem;
} */
/* .create-duration i {
  color: #111;
  font-size: 18px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
} */
.create-preview-board {
  border-top: 1px solid #707070;
  border-left: 1px solid #707070;
  border-right: 1px solid #707070;
  background-color: #ffffff;
  height: 100vh;
  width: 100%;
  padding: 2rem;
  white-space: nowrap;
}
