.CreatePuzzle_wrapper .navbar-addquestion {
  background-color: #f5f5f5;
  height: 4rem;
  border: 1px solid #707070;
  cursor: pointer;
}
.add-question-left .create-question-board {
  border-top: 1px solid #707070;
  border-left: 1px solid #707070;
  border-right: 2px solid #707070;
  background-color: #ffffff;
  height: 100vh;
  width: 100%;
  padding: 1rem 5rem 1rem 5rem;
  white-space: nowrap;
}
#add-question-edit p {
  color: #3a3120;
  opacity: 100%;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 1px;
  margin-left: 4rem;
}
#add-question-edit p.active {
  color: #3a3120;
  opacity: 100%;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 1px;
  margin-left: 4rem;
}
#duration-add-question p {
  color: #4b4b4b;
  font-size: 16px;
  float: right;
  margin-left: 34rem;
}
#duration-add-question input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  color: #4b4b4b;
  opacity: 0.5;
  font-size: 14px;
  margin-left: 10px;
  border-radius: 4px;
  padding: 0rem 1rem 0rem 1rem;
}
#preview-add-question {
  color: #4b4b4b;
  font-size: 14px;
  margin-left: 10rem;
  font-weight: 500;
}
#preview-close-add-question i {
  color: #030303;
  padding-left: 8rem;
  font-size: 18px;
  font-weight: 500;
}
.puzzle-table-crud span {
  color: #030303;
  font-size: 14px;
  padding-left: 1rem;
  cursor: pointer;
}
.create-preview-board .sc-dkrFOg {
  display: flex;
  margin-right: 1rem;
}
.create-preview-board .header {
  font-size: 15px;
}
.create-preview-board .sc-bcXHqe {
  font-size: 12px;
  padding-right: 6rem;
}
.puzzle-footer .puzzle-Save-button {
  background-color:#A8F020;
  color: #ffffff;
  font-size: 12px;
  border-radius: 4px;
  text-align: center;
  padding: 5px 15px;
  float: right;
}