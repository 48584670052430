.crossWord-wrapper {
  background-color: #91c1d4;
  height: auto;
  padding-bottom: 1rem;
}
.crossword-edit {
  padding: 2rem;
}
.time-button button {
  background-color: #fff;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #111111;
  color: #111111;
  font-size: 15px;
  padding: 0.5rem 0.8rem 0.5rem 0.2rem;
  border-radius: 8px;
}
.time-button button i {
  color: #e97838;
  padding-right: 0.5rem;
}
#Edit-button {
  background-color: #f0ac20;
  border: none;
  color: #fff;
  padding: 0.4rem 0.8rem;
  font-size: 20px;
  border-radius: 6px;
  margin-right: 2rem;
}
#close-circle {
  background-color: transparent;
  border: none;
  font-size: 28px;
}
.number-crossword .sc-dkrFOg {
  display: none;
}
.crossWord-wrapper h5 {
  color: #225a6f;
  width: 10%;
  position: absolute;
  display: block;
  left: 15rem;
  background-color: #fada0a;
  border-radius: 20px 25px 10px 40px/30px 40px 20px 10px;
  padding: 1.5rem;
}
.Number_Img {
  height: 80px;
  position: absolute;
  float: right;
  left: 28rem;
  top: 3rem;
}
.Number_Img1 {
  height: 80px;
  position: absolute;
  right: 25rem;
  top: 8rem;
}
#Cross-word-Submit {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 6%;
  margin-bottom: 1rem;
  background-color: #ff6700;
  border-radius: 49px;
  color: #fff;
  border: none;
  font-size: 14px;
  padding: 0.5rem;
  font-weight: bold;
}
/* .sc-gswNZR{
  background-color: #225A6F;
}
.crossword{
  background-color: #225A6F;
}
.grid{
  border-radius: 3px;
  color:#225A6F;
} */
