/* Create Tab CSS */
.create-view-buttons {
  display: inline-flex;
  margin-right: auto;
  margin-left: auto;
  width: 30%;
}
.create-view-buttons #create-Questions {
  color: #3a3120;
  opacity: 70%;
  font-size: 16px;
  padding-bottom: 1px;
}
#create-themes {
  color: #707070;
  opacity: 0.5;
  font-size: 16px;
}
.create-guideInfo button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  color: #3a3120;
  opacity: 1;
  font-size: 17px;
}
.create-duration {
  color: #4b4b4b;
  font-size: 16px;
  float: right;
  padding-right: 8rem;
}
.create-duration input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  color: #4b4b4b;
  opacity: 0.5;
  font-size: 14px;
  margin-left: 10px;
}
.create-preview {
  color: #4b4b4b;
  opacity: 1;
  font-size: 16px;
  padding-right: 3rem;
}
.create-field-body {
  background-color: #fff;
  height: 100vh;
}
.create-Puzzle {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  float: left;
  width: 100%;
  height: 100vh;
}
.create-Puzzle p {
  color: hsl(176, 97%, 36%);
  font-size: 16px;
  padding: 1.5rem 0rem 0rem 2rem;
  opacity: 100%;
  font-weight: 500;
}
.create-Puzzle .form-Dropdown {
  padding: 2rem;
  margin-bottom: 1rem;
}
.create-Puzzle .MuiInputBase-root {
  margin-bottom: 1rem;
  height: 2.5rem;
}
.create-Puzzle .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 1px solid #707070 !important;
}
.create-Puzzle .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #03b5aa !important;
}
.create-Puzzle .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #2b4255;
  font-size: 13px !important;
  font-weight: 600 !important;
}
.create-Puzzle .css-q8hpuo-MuiFormControl-root {
  font-size: 13px !important;
}

.create-next-button {
  text-align: center;
}
.create-next-button button {
  width: 80%;
  background: #68f020 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  height: 2rem;
  font-weight: 500;
  margin-top: 4rem;
}
.create-Notice-board {
  border-top: 1px solid #00000029;
  border-left: 1px solid #00000029;
  border-right: 1px solid #00000029;
  height: 100vh;
  width: 100%;
}
.create-Notice-board img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
.create-Notice-board p {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  font-size: 13px;
  color: #111111;
  opacity: 100;
}
