@import url(//db.onlinewebfonts.com/c/7cc6719bd5f0310be3150ba33418e72e?family=Comic+Sans+MS);
@import url(https://allfont.net/allfont.css?fonts=comic-sans-ms-bold);
.wordsearch-wrapper {
  background-color: #4ab9e7;
  height: auto;
  padding-bottom: 1.4rem;
}
.wordsearch-title {
  color: #fff;
}
.wordsearch-title h6 {
  font-size: 21px;
}
.wordsearch-title span {
  font-size: 16px;
}
.wordsearch-wrapper ._3Yz-4 {
  display: flex;
  flex-direction: row !important;
}
/* Grid and Type of Table Data */
.wordsearch-wrapper ._1oQTZ {
  display: none !important;
}
.wordsearch-wrapper ._2XiA2 button {
  border: none !important;
  border-radius: 6px;
  margin-left: 1rem;
  font-size: 22px !important;
  background-color: #e97838;
  color: #fff;
}
.wordsearch-wrapper ._1lZLV {
  width: 600px !important;
  height: 500px !important;
}
.wordsearch-wrapper ._3Jpt8 {
  border: 3px solid #4ab9e7 !important;
  background-color: #fff;
  color: #030303 !important;
  font-size: 17px !important;
  font-weight: 700;
  border-radius: 6px;
}
.wordsearch-wrapper ._2r0_S {
  display: block !important;
  position: absolute;
  right: 13rem;
  top: 5rem;
  background-color: #fada0a;
  border: 2px solid #225a6f;
  border-radius: 10% 50%;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 3rem 2rem;
}
.wordsearch-wrapper ._2r0_S::before,
.wordsearch-wrapper ._2r0_S::after {
  /* content: "\0020"; */
  display: block;
  position: absolute;
  top: -15px;
  left: 15px;
  z-index: 2;
  width: 0;
  height: 0;
  overflow: hidden;
  border: solid 15px transparent;
  border-top: 0;
  border-bottom-color: #225a6f;
}
.wordsearch-wrapper ._2r0_S::before {
  top: -18px;
  z-index: 1;
  border-bottom-color: #32557f;
}
.wordsearch-wrapper ._2r0_S h4 {
  color: #225a6f;
  font-family: Comic Sans MS;
  text-transform: capitalize !important;
  line-height: 3.5rem;
  font-size: 18px;
}

/* Reset Button CSS */
.wordsearch-wrapper ._addLi {
  display: none !important;
}

/* WordSearch Preview */
.Wordsearch-preview ._3Yz-4 {
  display: block;
  flex-direction: row !important;
}
/* Grid and Type of Table Data */
.Wordsearch-preview ._1oQTZ {
  display: none !important;
}
.Wordsearch-preview ._2XiA2 button {
  border: none !important;
  border-radius: 6px;
  margin-left: 1rem;
  font-size: 22px !important;
  background-color: #e97838;
  color: #fff;
}
.Wordsearch-preview ._1lZLV {
  width: 200px !important;
  height: 200px !important;
}
.Wordsearch-preview ._3Jpt8 {
  border: 3px solid #4ab9e7 !important;
  background-color: #fff;
  color: #030303 !important;
  font-size: 17px !important;
  font-weight: 700;
  border-radius: 6px;
}
.Wordsearch-preview ._2r0_S {
  display: block !important;
}
.Wordsearch-preview ._2r0_S h4 {
  color: #225a6f;
  /* font-family: Comic Sans MS; */
  text-transform: capitalize !important;
  /* line-height: 3.5rem; */
  font-size: 14px;
  text-align: left;
  padding-left: 3rem;
}

/* Reset Button CSS */
.Wordsearch-preview ._addLi {
  display: none !important;
}
