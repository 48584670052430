.home-Navbar {
  background-color: #f5f5f5;
  box-shadow: 0px 0px 8px #a3a1a1;
  padding-left: 1rem;
}
.create-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #70707080;
  color: #aaaaaa;
}
.view-button {
  background: #f0ac20 0% 0% no-repeat padding-box;
  color: #fbfbfb;
  border: 1px solid #f0ac20;
  opacity: 100%;
}
.view-button:hover {
  background: #f0ac20 0% 0% no-repeat padding-box;
}
.view-button:focus {
  background: #f0ac20 0% 0% no-repeat padding-box;
}
.view-button:active {
  background: #f0ac20 0% 0% no-repeat padding-box;
}
/* View Button Css */
.view-wrapper {
  background: #b7e6e4 0% 0% no-repeat padding-box;
  padding-left: 2rem;
  padding-right: 4rem;
}
.view-wrapper .nav-link {
  color: #030303;
  opacity: 100%;
  font-size: 14px;
  font-weight: 600;
}
.view-dropdown-form {
  margin-left: 2rem;
}
.view-dropdown-form .form-select {
  background-color: #fff;
  border: 1px solid #707070;
  color: #4b4b4b;
  font-size: 12px;
  text-align: left;
  width: 70%;
  font-weight: 600;
  opacity: 0.5;
}
.view-dropdown-form .form-select option {
  color: #4b4b4b;
  font-size: 12px;
}
/* Toggle Switch Active and Inactive CSS */
.box_1 {
  margin: 5px 0;
}

input[type="checkbox"].switch_1 {
  font-size: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 38px;
  height: 20px;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked {
  background: #4bbb46;
}

input[type="checkbox"].switch_1:after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked:after {
  left: calc(100% - 0.7em);
}
.View_Table_Button {
  width: 95%;
  margin-left: 2rem;
}
.View_Table_Button tr td {
  color: #4b4b4b;
  font-size: 14px;
  font-weight: 500;
  padding-left: 2rem;

  /* border-bottom: 2px solid #4B4B4B66;
  border-top: 2px solid #4B4B4B66;   */
}
.View_Table_Button tr td img {
  background-color: #f0ac20;
  width: 20px;
  color: #fff;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.View_Table_Button table td {
  padding: 1rem;
  border-right: 1px solid #918e96;
}

#type_data {
  color: #03b5aa;
  font-weight: 500;
}
