.login-container {
  width: 100%;
  padding: 0;
  margin: 0;
}
.login-container img {
  margin: 5rem 0rem 0rem 0rem;
  height: 80%;
}
@media (max-width: 991.98px) {
  .login-container .bg {
    height: 500px;
  }
}
.right-contents {
  padding: 9rem 0;
}
.login-container .right-contents,
.login-container .bg {
  width: 50%;
}
.right-contents h1 {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #111;
  font-weight: 700;
}
.right-contents p {
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  color: #111;
  letter-spacing: 0.2px;
  opacity: 100%;
  font-weight: 500;
}
.right-contents span {
  font-size: 0.6rem;
  font-family: "Poppins", sans-serif;
  color: #111;
  letter-spacing: 0.2px;
  opacity: 70%;
}
@media (max-width: 1199.98px) {
  .login-container .right-contents,
  .login-container .bg {
    width: 100%;
  }
}
.label_signin {
  font-size: 16px;
  letter-spacing: 0px;
  color: #020723;
  opacity: 0.87;
  padding-right: 15px;
}
.right-contents .form-group {
  overflow: hidden;
  margin-bottom: 0;
}
.right-contents .form-group label {
  font-size: 0.8rem;
  color: #111;
  font-weight: 500;
}
.right-contents .form-group input {
  width: 80%;
  height: 40px;
  font-size: 0.8rem;
  background: #edf2f5;
  border: none;
}
.controls .form-check-input {
  border: 2px solid #007b85;
  margin-right: 0.5rem;
  top: 455px;
  left: 1213px;
  width: 20px;
  height: 20px;
  opacity: 1;
}

.controls .form-check-input:checked {
  background-color: #007b85;
  border-color: #007b85;
}
.form-check-input:focus,
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none !important;
}
.controls label {
  font-size: 0.8rem;
  color: #111;
  font-weight: 500;
}

.login-container .bg {
  background-size: cover;
  background-position: center;
}

.login-container a {
  color: #888;
  text-decoration: underline;
}

.login-container .btn {
  height: 54px;
  padding-left: 30px;
  padding-right: 30px;
}
.login-container .forgot-pass {
  /* float: right;
    position: relative;
    top: 2px;
    font-size: 14px; */
  float: right;
  text-align: right;
  font-size: 12px;
  letter-spacing: 0.47px;
  color: #007b85;
  font-weight: 500;
  text-decoration: none;
  padding-right: 5rem;
}
.Signin-Button button {
  width: 80%;
  height: 20px;
  background: #f0ac20 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: #f0ac20;
  color: #ffffff;
  opacity: 0.87;
  margin-top: 2rem;
  font-size: 16px;
}
.Signin-Button button:hover {
  color: #ffffff;
  background: #f0ac20 0% 0% no-repeat padding-box;
}
.eye-visible {
  position: absolute;
  top: 18.5rem;
  right: 20.5rem;
  color: #007b85;
  font-weight: 600;
}
@media (max-width: 1250px) {
  .eye-visible {
    position: absolute;
    top: 18.5rem;
    right: 15rem;
    color: #007b85;
    font-weight: 600;
  }
}
@media (max-width: 1200px) {
  .eye-visible {
    display: none;
  }
}
.eye-visible1 {
  position: absolute;
  top: 24.1rem;
  right: 20.5rem;
  color: #007b85;
  font-weight: 600;
}
@media (max-width: 1250px) {
  .eye-visible1 {
    position: absolute;
    top: 24.1rem;
    right: 15rem;
    color: #007b85;
    font-weight: 600;
  }
}
@media (max-width: 1200px) {
  .eye-visible1 {
    display: none;
  }
}
/* @media (max-width: 1199px) {
    .eye-visible1 {
      position: absolute;
      top: 59.9rem;
      right: 26.3rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 1150px) {
    .eye-visible1 {
      position: absolute;
      top: 59.9rem;
      right: 25rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 1099px) {
    .eye-visible1 {
      position: absolute;
      top: 59.9rem;
      right: 23rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 1050px) {
    .eye-visible1 {
      position: absolute;
      top: 59.9rem;
      right: 21rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 999px) {
    .eye-visible1 {
      position: absolute;
      top: 52.5rem;
      right: 22rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 950px) {
    .eye-visible1 {
      position: absolute;
      top: 52.5rem;
      right: 20rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 899px) {
    .eye-visible1 {
      position: absolute;
      top: 52.5rem;
      right: 19rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 850px) {
    .eye-visible1 {
      position: absolute;
      top: 52.5rem;
      right: 17rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 799px) {
    .eye-visible1 {
      position: absolute;
      top: 52.5rem;
      right: 16rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 750px) {
    .eye-visible1 {
      position: absolute;
      top: 63.5rem;
      right: 12rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 699px) {
    .eye-visible1 {
      position: absolute;
      top: 63.5rem;
      right: 10rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 650px) {
    .eye-visible1 {
      position: absolute;
      top: 63.5rem;
      right: 9rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 599px) {
    .eye-visible1 {
      position: absolute;
      top: 63.5rem;
      right: 11rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 550px) {
    .eye-visible1 {
      position: absolute;
      top: 63.5rem;
      right: 9rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 499px) {
    .eye-visible1 {
      position: absolute;
      top: 58.4rem;
      right: 7rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 450px) {
    .eye-visible1 {
      position: absolute;
      top: 57.8rem;
      right: 7rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  
  @media (max-width: 1199px) {
    .eye-visible {
      position: absolute;
      top: 59.9rem;
      right: 26.3rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 1150px) {
    .eye-visible {
      position: absolute;
      top: 59.9rem;
      right: 25rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 1099px) {
    .eye-visible {
      position: absolute;
      top: 59.9rem;
      right: 23rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 1050px) {
    .eye-visible {
      position: absolute;
      top: 59.9rem;
      right: 21rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 999px) {
    .eye-visible {
      position: absolute;
      top: 52.5rem;
      right: 22rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 950px) {
    .eye-visible {
      position: absolute;
      top: 52.5rem;
      right: 20rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 899px) {
    .eye-visible {
      position: absolute;
      top: 52.5rem;
      right: 19rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 850px) {
    .eye-visible {
      position: absolute;
      top: 52.5rem;
      right: 17rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 799px) {
    .eye-visible {
      position: absolute;
      top: 52.5rem;
      right: 16rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 750px) {
    .eye-visible {
      position: absolute;
      top: 63.5rem;
      right: 12rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 699px) {
    .eye-visible1 {
      position: absolute;
      top: 63.5rem;
      right: 10rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 650px) {
    .eye-visible {
      position: absolute;
      top: 63.5rem;
      right: 9rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 599px) {
    .eye-visible {
      position: absolute;
      top: 63.5rem;
      right: 11rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 550px) {
    .eye-visible {
      position: absolute;
      top: 63.5rem;
      right: 9rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 499px) {
    .eye-visible1 {
      position: absolute;
      top: 52.5rem;
      right: 7rem;
      color: #007b85;
      font-weight: 600;
    }
  }
  @media (max-width: 450px) {
    .eye-visible{
      position: absolute;
      top: 52.5rem;
      right: 7rem;
      color: #007b85;
      font-weight: 600;
    }
  } */
/* @media (max-width: 1150px) {
    .eye-visible{
      position: relative;
    top: -10rem;
    right: 2rem;
    color: #007B85;
    font-weight: 600;
    }
  } */
/* .eye-visible svg{
    fill: #007B85;
  } */
